// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs';
import 'bootstrap-datepicker';
import 'bootstrap-progressbar/bootstrap-progressbar';
import 'chart.js';
import 'chosen-js';
import 'form-serializer';
import jQuery from 'jquery';
import 'jquery-form';
import 'jquery-ui/ui/widgets/sortable';
import 'jquery-validation';
import toastr from 'toastr';

import 'channels';
import 'global';
import 'stylesheets';

import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.css';
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker3.css';
import 'bootstrap-progressbar/css/bootstrap-progressbar-3.3.4.css';
import 'toastr/toastr.scss';

// https://rubyyagi.com/how-to-use-bootstrap-and-jquery-in-rails-6-with-webpacker/
global.$ = global.jQuery = jQuery;

global.toastr = toastr;

Rails.start();
