import './ckeditor4_basepath';
import 'ckeditor4';
import 'ckeditor4/adapters/jquery';

CKEDITOR.config.customConfig = '';
CKEDITOR.config.contentsCss = '/assets/ckeditor/custom_contents.css';
CKEDITOR.replaceClass = '';
CKEDITOR.disableAutoInline = true;
CKEDITOR.config.language = 'en';
CKEDITOR.config.disableNativeSpellChecker = false;
CKEDITOR.config.removePlugins = 'contextmenu,tabletools,tableselection,liststyle';
CKEDITOR.config.extraPlugins = 'font,colorbutton';
CKEDITOR.config.toolbar_Pure = [
  { name: 'document',    items: [ 'Source'] },
  { name: 'clipboard',   items: [ 'Cut','Copy','Paste','PasteText','PasteFromWord','-','Undo','Redo' ] },
  { name: 'basicstyles', items: [ 'Bold','Italic','Underline','Strike','Subscript','Superscript','-','RemoveFormat' ] },
  { name: 'paragraph',   items: [ 'NumberedList','BulletedList' ] },
  { name: 'styles',      items: [ 'Styles','Font','FontSize' ] },
  { name: 'colors',      items: [ 'TextColor','BGColor' ] },
  { name: 'insert',      items: [ 'SpecialChar'] }
];
CKEDITOR.config.stylesSet = [
  { name: 'Editor Table', element: 'table', attributes: { 'class': 'editor-table' } },
  { name: 'Sqrt', element: 'span', attributes: { 'class': 'sqrt' } },
  { name: 'Sqrt Value', element: 'span', attributes: { 'class': 'sqrt_value' } }
];
CKEDITOR.config.toolbar = 'Pure';
CKEDITOR.config.height = '100';
CKEDITOR.config.extraAllowedContent = 'table tr td th';

$(() => {
  $('.ckeditor-4').ckeditor(function () {
    this.on('key', function (event) {
      var key = event.data.keyCode;
      keyUpEvent(this, key);
    });

    this.focus();
  });
});
