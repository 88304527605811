import ClassicEditorBase from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';

import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
import UnderlinePlugin from '@ckeditor/ckeditor5-basic-styles/src/underline';
import OverlinePlugin from '../ckeditor5-custom-plugins/src/overline';
import StrikethroughPlugin from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
import SubscriptPlugin from '@ckeditor/ckeditor5-basic-styles/src/subscript';
import SuperscriptPlugin from '@ckeditor/ckeditor5-basic-styles/src/superscript';
import RemoveFormatPlugin from '@ckeditor/ckeditor5-remove-format/src/removeformat';
import ListPlugin from '@ckeditor/ckeditor5-list/src/list';
import HeadingPlugin from '@ckeditor/ckeditor5-heading/src/heading';
import FontFamilyPlugin from '@ckeditor/ckeditor5-font/src/fontfamily';
import FontSizePlugin from '@ckeditor/ckeditor5-font/src/fontsize';
import FontColorPlugin from '@ckeditor/ckeditor5-font/src/fontcolor';
import FontBackgroundColorPlugin from '@ckeditor/ckeditor5-font/src/fontbackgroundcolor';
import SpecialCharactersPlugin from '@ckeditor/ckeditor5-special-characters/src/specialcharacters';
import SpecialCharactersEssentialsPlugin from '@ckeditor/ckeditor5-special-characters/src/specialcharactersessentials';

class ClassicEditor extends ClassicEditorBase {}
global.CKEditor5ClassicEditor = ClassicEditor;

ClassicEditor.builtinPlugins = [
  EssentialsPlugin,
  BoldPlugin,
  ItalicPlugin,
  UnderlinePlugin,
  OverlinePlugin,
  StrikethroughPlugin,
  SubscriptPlugin,
  SuperscriptPlugin,
  RemoveFormatPlugin,
  ListPlugin,
  HeadingPlugin,
  FontFamilyPlugin,
  FontSizePlugin,
  FontColorPlugin,
  FontBackgroundColorPlugin,
  SpecialCharactersPlugin,
  SpecialCharactersEssentialsPlugin
];
ClassicEditor.defaultConfig = {
  toolbar: {
    items: [
      'undo',
      'redo',
      '|',
      'bold',
      'italic',
      'underline',
      'overline',
      'strikethrough',
      'subscript',
      'superscript',
      'removeFormat',
      '|',
      'numberedList',
      'bulletedList',
      '|',
      'fontFamily',
      'fontSize',
      'fontColor',
      'fontBackgroundColor',
      '|',
      'specialCharacters'
    ]
  },
  language: 'en'
};

global.ckeditor5EnterEventHandler = function (ckeditor) {
  return function (_eventInfo, _data, _dataIsSoft) {
    const input = $(ckeditor.getData()).text();
    const maxShortcutLength =
      Math.max(...$('#standard-notes option').map((_i, option) => $(option).text().trim().length));

    if (input[0] === '#' && input.trim().length <= maxShortcutLength) {
      const $selectedOption =
        $('#standard-notes option').filter(function (_i, option) {
          const $option = $(option);

          return $option.text().trim().toLowerCase() === input.trim().toLowerCase();
        });

      if ($selectedOption.length > 0) {
        setTimeout(
          () => {
            ckeditor.setData($selectedOption.val());
          },
          0
        );
      }
    }
  };
};

$(() => {
  document.querySelectorAll('.ckeditor-5').forEach((ckeditorTextarea) => {
    ClassicEditor.create(ckeditorTextarea).then((ckeditor) => {
      ckeditorTextarea.ckeditor = ckeditor;
      ckeditor.listenTo(
        ckeditor.editing.view.document,
        'enter',
        ckeditor5EnterEventHandler(ckeditor)
      );
    });
  });
});
